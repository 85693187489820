<template>
  <div class="main_right" v-if="$route.name == 'question'">
    <!-- 巩固错词 -->
    <div class="consolidate">
      <div class="icon">
        <div class="imgx">
          <img src="../../../assets/images/student/wrongicon.png" alt="">
        </div>
        <div class="text">
          <p class="p1">今日错词{{ wrong_data.jrcc }}个 </p>
          <p class="p2">现在巩固，更有利于记忆哦</p>
        </div>
      </div>
      <div class="ccTotal img1 img" @click="link">
        <div class="imgx">
          <img src="../../../assets/images/student/wrongicon.png" alt="">
        </div>
        <div>
          <p>错词总数{{ wrong_data.cczs }}个</p>
          <el-button class="li" type="danger" size="medium" round>查看错题表</el-button>
        </div>
      </div>
      <div class="btn" @click="todayLink">
        <span>巩固错词</span>
      </div>
    </div>
    <!-- 按专项练习 -->
    <div class="specia">
      <p class="pactive">按专项练习</p>
    </div>
    <!-- 四个白块 -->
    <div class="boxs">
      <div class="box1 box" @click="specialLink('yyh')">
        <p> <img src="../../../assets/images/student/one.png" alt=""> </p>
        <div>
          <img src="../../../assets/images/student/yyh.png" alt="">
          <span>看英选义</span>
        </div>
      </div>
      <div class="box2 box" @click="specialLink('tyxy')">
        <p> <img src="../../../assets/images/student/two.png" alt=""> </p>
        <div>
          <img src="../../../assets/images/student/tyby.png" alt="">
          <span>听音辨英</span>
        </div>
      </div>
      <div class="box3 box" @click="specialLink('kypy')">
        <p> <img src="../../../assets/images/student/three.png" alt=""> </p>
        <div>
          <img src="../../../assets/images/student/kypc.png" alt="">
          <span>看义拼词</span>
        </div>
      </div>
      <div class="box4 box" @click="specialLink('hyy')">
        <p> <img src="../../../assets/images/student/four.png" alt=""> </p>
        <div>
          <img src="../../../assets/images/student/hyy.png" alt="">
          <span>看义选英</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getTrains, getGroupStudentCount, getErrorWordHome, getWrongWords } from '@/api/student'
export default {
  data() {
    return {
      options: [],
      source: null, // 错词来源
      wrong_data: {
        bjpj: 0,
        cczs: 0,
        hyy: 0,
        hyyList: [],
        jrcc: 0,
        jrccList: [],
        kypy: 0,
        kypyList: [],
        tyxy: 0,
        tyxyList: [],
        xzpj: 0,
        yyh: 0,
        yyhList: []
      },
      currentData: {}
    }
  },
  created() {
    // getTrains({ yhid: this.$store.state.userInfo.yhid, yycdid: this.$store.state.yycdid }).then(res => {
    //   if (res.success) {
    //     if (res.result.length) {
    //       this.options = res.result
    //       this.source = this.options[0].jxid
    //     } else {
    //       this.$message.warning('未参加集训，暂无数据')
    //     }
    //   } else {
    //     this.$message.error(res.resultDesc)
    //   }
    // })
    getErrorWordHome({xsid:this.$store.state.userInfo.yhid}).then(ele => {
            if (ele.success) {
              this.wrong_data = ele.result
            } else {
              this.$message.error(ele.resultDesc)
            }
          })
  },
  methods: {
    // 去学习，今日错词
    todayLink() {
      if (this.wrong_data.jrccList.length) {
        getWrongWords({ dcidList: this.wrong_data.jrccList }).then(res => {
          if (res.success) {
            sessionStorage.setItem('words', JSON.stringify(res.result))
            this.$router.replace({ name: 'wordcontent', query: { source: 'wrong' } })
          } else {
            this.$message.error(res.resultDesc)
          }
        })
      } else {
        this.$message.warning('今天没有做错的单词')
      }
    },
    // 去学习,专项练习
    specialLink(type) {
      if (this.wrong_data[type + 'List'].length) {
        getWrongWords({ dcidList: this.wrong_data[type + 'List'] }).then(res => {
          if (res.success) {
            sessionStorage.setItem('words', JSON.stringify(res.result))
            this.$router.replace({ name: 'wordcontent', query: { source: 'wrong' } })
          } else {
            this.$message.error(res.resultDesc)
          }
        })
      } else {
        this.$message.warning('此题型暂无做错的单词')
      }
    },
    // 跳转错词列表
    link() {
      const element = document.querySelector('.img1')
      element.classList.add('animate__animated', 'animate__zoomOutUp')
      setTimeout(() => {
        const e = document.getElementsByClassName('home')[0];
        e.classList.add('animate__animated', 'animate__fadeOut')
      }, 500)
      setTimeout(() => {
        this.$router.replace({ name: 'wrongquestion', query: { jxid: this.source, bjid: this.currentData.bjid } })
      }, 1000);
    },
  },
  watch: {
    source(newV, oldV) {
      getGroupStudentCount({ xsid: this.$store.state.userInfo.yhid, jxid: newV }).then(res => {
        if (res.success) {
          if (!res.result.xzid) {
            this.$message.warning('未查到所在小组，暂无数据')
            return false
          }
          if (!res.result.bjid) {
            this.$message.warning('未查到所在班级，暂无数据')
            return false
          }
          this.currentData.bjid = res.result.bjid
          let data = res.result
          data.lyid = newV
          data.xsid = this.$store.state.userInfo.yhid
          getErrorWordHome(data).then(ele => {
            if (ele.success) {
              this.wrong_data = ele.result
            } else {
              this.$message.error(ele.resultDesc)
            }
          })
        } else {
          this.$message.error(res.resultDesc)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.main_right {
  background-color: rgb(253, 253, 253);
  box-shadow: 0 0 8px 4px rgba(89, 144, 255, 0.2);
  border-radius: 10px;
  margin-left: 13px;
  width: calc(100% - 13vw);
  height: 100%;
  float: left;
  box-sizing: border-box;
  padding: 0 2%;
  .imgs {
    width: 100%;
    height: 22%;
    .img1 {
      background: url("../../../assets/images/student/ban1.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      position: relative;
      li {
        position: absolute;
        bottom: 0.5vh;
        left: 50%;
        font-size: 1vw;
        color: #5990ff;
        transform: translateX(-50%);
      }
    }
    .img2 {
      background: url("../../../assets/images/student/ban2.png") no-repeat;
      background-size: 100% 100%;
    }
    .img3 {
      background: url("../../../assets/images/student/ban3.png") no-repeat;
      background-size: 100% 100%;
    }
    .img4 {
      background: url("../../../assets/images/student/ban4.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      p {
        width: 100% !important;
        height: 50% !important;
        position: relative !important;
        padding: 0 !important;
        span {
          position: absolute !important;
          top: 50% !important;
          left: 50% !important;
          transform: translate(-50%, -50%) !important;
        }
      }
      .el-select {
        margin-left: 50%;
        transform: translateX(-50%);
        width: 80%;
      }
    }
    .img {
      width: 23%;
      height: 100%;
      margin-right: 2.65%;
      float: left;
      p {
        width: 100%;
        height: 50%;
        font-size: 1.2vw;
        color: #fff;
        box-sizing: border-box;
        padding-left: 10%;
        position: relative;
        span {
          position: absolute;
          top: 50%;
          transform: translateY(-30%);
        }
      }
      p:nth-child(2) {
        height: 40%;
        font-size: 1.5vw;
        box-sizing: border-box;
        padding-left: 23%;
        span {
          top: 0;
          transform: translateY(0);
        }
      }
    }
    .img:last-child {
      margin-right: 0;
    }
  }
  .consolidate {
    margin-top: 50px;
    width: 100%;
    height: 19%;
    box-shadow: 0 0 4px 4px rgba(89, 144, 255, 0.2);
    border-radius: 4px;
    padding: 0 2%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon {
      display: flex;
      .text {
        .p1 {
          color: #5990ff;
          margin-left: 5px;
          font-size: 24px;
        }
        .p2 {
          margin-top: 15px;
          margin-left: 5px;
        }
      }
    }
    .ccTotal {
      display: flex;
      p {
        color: #5990ff;
        font-size: 24px;
        margin-left: 5px;
      }
      .li {
        margin-top: 18px;
        margin-left: 5px;
      }
    }
    .btn {
      cursor: pointer;
      span {
        display: block;
        width: 8vw;
        height: 3vw;
        color: #fff;
        font-size: 1.2vw;
        border-radius: 30px;
        text-align: center;
        line-height: 3vw;
        background: #5990ff;
        box-shadow: 0px 0px 10px 2px rgba(89, 144, 255, 0.3);
      }
    }
  }
  .specia {
    margin-top: 15px;
    width: 100%;
    height: 9%;
    border-radius: 4px;
    box-shadow: 0 0 4px 4px rgba(89, 144, 255, 0.2);
    padding-left: 1%;
    box-sizing: border-box;
    font-size: 1.2vw;
    position: relative;
    color: #333;
    p {
      height: 100%;
      width: fit-content;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      line-height: 7vh;
      cursor: pointer;
    }
    .pactive {
      border-bottom: 2px solid #5990ff;
    }
  }
  .boxs {
    margin-top: 15px;
    width: 100%;
    height: calc(50% - 30px);
    .box {
      width: 24%;
      height: 100%;
      border-radius: 4px;
      margin-right: 1.3%;
      float: left;
      box-shadow: 0 0 4px 4px rgba(89, 144, 255, 0.2);
      padding: 1vw;
      box-sizing: border-box;
      cursor: pointer;
      p {
        width: 2.2vw;
        height: 2.2vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      div {
        width: 50%;
        height: calc(100% - 3.5vw);
        margin-left: 4vw;
        position: relative;
        img {
          display: block;
          width: 100%;
          height: 80%;
        }
        span {
          position: absolute;
          bottom: -1vw;
          left: 50%;
          transform: translateX(-50%);
          color: #333;
          font-size: 1.1vw;
        }
      }
    }
    .box:last-child {
      margin-right: 0;
    }
  }
}
</style>