var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$route.name == 'question')?_c('div',{staticClass:"main_right"},[_c('div',{staticClass:"consolidate"},[_c('div',{staticClass:"icon"},[_vm._m(0),_c('div',{staticClass:"text"},[_c('p',{staticClass:"p1"},[_vm._v("今日错词"+_vm._s(_vm.wrong_data.jrcc)+"个 ")]),_c('p',{staticClass:"p2"},[_vm._v("现在巩固，更有利于记忆哦")])])]),_c('div',{staticClass:"ccTotal img1 img",on:{"click":_vm.link}},[_vm._m(1),_c('div',[_c('p',[_vm._v("错词总数"+_vm._s(_vm.wrong_data.cczs)+"个")]),_c('el-button',{staticClass:"li",attrs:{"type":"danger","size":"medium","round":""}},[_vm._v("查看错题表")])],1)]),_c('div',{staticClass:"btn",on:{"click":_vm.todayLink}},[_c('span',[_vm._v("巩固错词")])])]),_vm._m(2),_c('div',{staticClass:"boxs"},[_c('div',{staticClass:"box1 box",on:{"click":function($event){return _vm.specialLink('yyh')}}},[_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"box2 box",on:{"click":function($event){return _vm.specialLink('tyxy')}}},[_vm._m(5),_vm._m(6)]),_c('div',{staticClass:"box3 box",on:{"click":function($event){return _vm.specialLink('kypy')}}},[_vm._m(7),_vm._m(8)]),_c('div',{staticClass:"box4 box",on:{"click":function($event){return _vm.specialLink('hyy')}}},[_vm._m(9),_vm._m(10)])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgx"},[_c('img',{attrs:{"src":require("../../../assets/images/student/wrongicon.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgx"},[_c('img',{attrs:{"src":require("../../../assets/images/student/wrongicon.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"specia"},[_c('p',{staticClass:"pactive"},[_vm._v("按专项练习")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("../../../assets/images/student/one.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../../assets/images/student/yyh.png"),"alt":""}}),_c('span',[_vm._v("看英选义")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("../../../assets/images/student/two.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../../assets/images/student/tyby.png"),"alt":""}}),_c('span',[_vm._v("听音辨英")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("../../../assets/images/student/three.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../../assets/images/student/kypc.png"),"alt":""}}),_c('span',[_vm._v("看义拼词")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("../../../assets/images/student/four.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../../assets/images/student/hyy.png"),"alt":""}}),_c('span',[_vm._v("看义选英")])])
}]

export { render, staticRenderFns }